import React from "react";

import { Edit, Create } from "cms-toolbox/ReactAdminHoc";

import List from "cms-toolbox/List";

import { SimpleForm } from "cms-toolbox/FormHoc";
import ListContent from "cms-toolbox/ListContent";
import { FieldSet } from "cms-toolbox/FormContent";
import { Typography } from "@material-ui/core";

import { tariff as schema } from "taxi-schema";

import { Filter, BooleanInput, useGetOne, CRUD_GET_ONE } from "react-admin";

import { SelectInput, SearchInput } from "cms-toolbox/inputHoc";

const TariffFilter = props => (
    <Filter {...props}>
       
       
        <SearchInput alwaysOn source="q" />
    </Filter>
);

export class TariffList extends React.Component {
    render = () => {
        return (
            <List
                title="Tariffs"
                {...this.props}
                perPage={20}
                filters={<TariffFilter />}
            >
                <ListContent
                    editAction
                    {...this.props}
                    schema={[...schema,{
                        source: "changed",type:"date",label:"Last change" 
                    }]}
                    showFields={[
                        { source: "place", media: "small" },
                        { source: "car", media: "small" },
                        { source: "stationwagon", media: "small" },
                        { source: "van", media: "small" },
                        { source: "changed",media:"medium" },
                    ]}
                    //isTree
                />
            </List>
        );
    };
}

const TariffTitle = ({ record }) => {
    return (
        <span>
            {record.id
                ? `Tariff: Schiphol - "${record.place || ""}"`
                : "New tariff for Schiphol"}
        </span>
    );
};

export const TariffEdit = props => (
    <Edit title={<TariffTitle />} {...props}>
        <SimpleForm redirect={false}>
            <FieldSet schemaFragment={schema} entityId={props.id} />
        </SimpleForm>
    </Edit>
);

export const TariffCreate = props => (
    <Create title={<TariffTitle />} {...props}>
        <SimpleForm>
            <FieldSet schemaFragment={schema} />
        </SimpleForm>
    </Create>
);

