import React, { useState } from "react";
import { useForm } from "react-final-form";
import { useInput } from "ra-core";
import {
    fetchEnd,
    fetchStart,
    useNotify,
    Button,
    SaveButton,
    SimpleForm
} from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import dataProvider from "cms-config/dataProvider";

import schemas from "taxi-schema";
import { FieldSet } from ".";

// getting the submit handler of the inner form, and storing it in closure
// note how react-final-form also uses closure to support an external submit
// see: https://codesandbox.io/s/1y7noyrlmq
// but they use a render prop to extract form state, I don't see render props in
// react-admin's Form so came up with this hack
let submitQC;
const QCSubmitExtractor = ({ children }) => {
    submitQC = useForm().submit;
    return children;
};

export default props => {
    const [showDialog, setShowDialog] = useState(false);
    // const [, setData] = useState({});
    const { change } = useForm();
    const notify = useNotify();

    const {
        input: { value: prevVal }
    } = useInput(props);

    // this get executed on every render, redundant but don't know how to avoid
    // since you can't use a hook inside useEffect(() => {..}, [data]) to only
    // have this execute when `data` changes

    /*useGetMatching(
        props.resource,
        { page: 1, perPage: 25 },
        {
            field: "id",
            order: "DESC"
        },
        {},
        "post_id",
        "comments",
        {}
    );*/

    const handleOpenDialog = () => setShowDialog(true);

    const handleCloseDialog = () => setShowDialog(false);

    const handleSave = () => {
        submitQC();
        //setShowDialog(false);
    };

    const handleSubmit = async values => {
        fetchStart();

        try {
            const e = await dataProvider("CREATE", props.resource, {
                data: values
            });
            if(e instanceof Error){
                
                return notify(e.message, "error");
            }
            const { data } = e
            // setData(data);

            change(
                props.source,
                props.multiple
                    ? Array.isArray(prevVal)
                        ? [...prevVal, data.id]
                        : [data.id]
                    : data.id
            );
            setShowDialog(false);
            fetchEnd();
        } catch (e) {
            fetchEnd();
            notify(e.message, "error");
        }
    };

    const { resource } = props;
    const schema = schemas[resource];
    const title = `Create ${resource}`;

    return (
        <>
            <Button onClick={handleOpenDialog} label="ra.action.create">
                <IconContentAdd />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseDialog}
                aria-label={title}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <SimpleForm
                        submitOnEnter={false}
                        resource={resource}
                        save={handleSubmit}
                        toolbar={null}
                    >
                        <QCSubmitExtractor>
                            <FieldSet schemaFragment={schema} />
                        </QCSubmitExtractor>
                    </SimpleForm>
                </DialogContent>
                <DialogActions>
                    <SaveButton
                        // didn't look yet what the equivalent is of below
                        // saving={isSubmitting}
                        onClick={handleSave}
                        // need to provide something here or it crashes
                        // we handle everything in `handleSave`
                        handleSubmitWithRedirect={() => {}}
                    />
                    <Button
                        label="ra.action.cancel"
                        onClick={handleCloseDialog}
                    >
                        <IconCancel />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
