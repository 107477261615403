import jsonApiClient from "cms-config/jsonApiClient/index";

import schemas, { timestamp } from "taxi-schema";

import { apiUrl } from "cms-config/domain";

const settings = {
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ApiVersion: timestamp
    }
};

const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

const traverse = async (nodes, schemaFragment) => {
    for (const key in nodes) {
        const col = schemaFragment.find(f => f.source === key);
        if (!col) {
            delete nodes[key];
        } else if (nodes[key]) {
            if (col.type === "image") {
                if (typeof nodes[key] === "object" && nodes[key].new) {
                    const newFile = nodes[key].new.rawFile;
                    nodes[key] = await convertFileToBase64(newFile).then(
                        base64Picture => {
                            return {
                                src: base64Picture,
                                crop: nodes[key].crop,
                                alt: nodes[key].alt,
                                meta: {
                                    name: newFile.name,
                                    size: newFile.size,
                                    mime: newFile.type
                                }
                            };
                        }
                    );
                }
            } else if (col.type === "array") {
                if (Array.isArray(nodes[key])) {
                    nodes[key] = await Promise.all(
                        nodes[key].map(
                            async obj => await traverse(obj, col.sub)
                        )
                    );
                } else {
                    nodes[key] = [];
                }
            }
        }
    }
    return nodes;
};

const convertRequests = requestHandler => async (type, resource, params) => {
    if (~["CREATE", "UPDATE"].indexOf(type)) {
        const schema = schemas[resource==='revision'?'product':resource.split("/").pop()];

        if (schema) {
            params.data = await traverse(params.data, schema);
        }
    } else if (type === "GET_MANY") {
        for (const field in params) {
            if (Array.isArray(params[field])) {
                params[field] = params[field].filter(
                    f => typeof f !== "object"
                );
            }
        }
    }
    return requestHandler(type, resource, params);
};

export default convertRequests(jsonApiClient(apiUrl, settings));

//export default addUploadFeature(jsonApiClient(url,settings))
