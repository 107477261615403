import React from "react";

import {
  TopToolbar,
  BulkExportButton,
  BulkDeleteButton,
  downloadCSV
} from "react-admin";
import { ImportButton } from "react-admin-import-csv";
import { CreateButton } from "ra-ui-materialui";

import jsonExport from "jsonexport/dist";

import schemas from "taxi-schema";

import { sanitizeListRestProps, useListContext } from "ra-core";

import { getPermissions } from "cms-config/authProvider";

const exportTraverse = (nodes, schemaFragment, isSub) => {
  for (const key in nodes) {
    const col = schemaFragment.find(f => f.source === key);
    if (!col || col.type === "reference") {
      delete nodes[key];
    } else if (nodes[key]) {
      if (col.type === "array") {
        if (Array.isArray(nodes[key])) {
          nodes[key] = nodes[key].map(obj =>
            exportTraverse(obj, col.sub, true)
          );
        } else {
          nodes[key] = [];
        }
        if (!isSub) {
          nodes[key] = JSON.stringify(nodes[key]);
        }
      }
    }
  }
  return nodes;
};

const exporter = (entities, _0, _1, resource) => {
  const schema = schemas[resource];
  if (!schema) {
    return false;
  }

  const entitiesForExport = entities.map(entity =>
    exportTraverse(entity, schema)
  );

  jsonExport(
    entitiesForExport,
    {
      //  headers: ['id', 'title', 'author_name', 'body'] // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, resource);
    }
  );
};

const importer = resource => {
  const schema = schemas[resource];

  return (v, key) => {
    const col = schema.find(f => f.source === key);

    if (col) {
      if (col.type === "array") {
        try {
          const parsed = JSON.parse(v);
          if (Array.isArray(parsed)) {
            return parsed;
          }
        } catch (e) {}
        return [];
      } else if (col.type === "boolean") {
        switch (v.toLowerCase()) {
          case "true":
            return true;
          case "false":
            return false;
          default:
            return !!v;
        }
      }
      if (col.type === "number") {
        const parsed = parseInt(v);
        return typeof parsed === "number" && !isNaN(parsed) ? parsed : null;
      }
    }
    return v;
  };
};
/*
export const ListActions = props => {
  const { className, basePath, resource } = props;

  return (
    <TopToolbar className={className}>
      <CreateButton basePath={basePath} />

      <ImportButton
        {...props}
        parseConfig={{
          header: true,
          transform: importer(resource)
        }}
      />
    </TopToolbar>
  );
};*/

export const ListActions = props => {
  const { className, exporter, filters, ...rest } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total
  } = useListContext(props);
  return React.useMemo(
    () => {

       const { fullAccess } = getPermissions();
      return (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button"
          })}
        {hasCreate && <CreateButton basePath={basePath} />}
        {fullAccess?<ImportButton
          {...props}
          parseConfig={{
            header: true,
            transform: importer(props.resource)
          }}
        />:null}
      </TopToolbar>)
    },
    [resource, displayedFilters, filterValues, selectedIds, filters, total] // eslint-disable-line react-hooks/exhaustive-deps
  );
};

export const ListBulkActions = props => {
  return (
    <React.Fragment>
      <BulkExportButton {...props} exporter={exporter} />
      <BulkDeleteButton {...props} />
    </React.Fragment>
  );
};
