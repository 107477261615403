import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from "react-admin";
import dataProvider from "./dataProvider";

import Cookies from "js-cookie";

import { hostname } from "cms-config/domain";

const cookieName = process.env.REACT_APP_EDITOR_COOKIE;
const cookieParams = { domain: hostname.replace(/^cms\./, "") };

export const getPermissions=()=>{
    const role=localStorage.getItem("role");
    return {
        fullAccess:role!=="supplier"
    }
}

export default (type, params) => {
    // return Promise.resolve();

    // called when the user attempts to log in

    if (type === AUTH_LOGIN) {
        return dataProvider("POST_TO_AUTH", "login", params).then(
            ({ data }) => {
                if (!data || data.error) {
                    let msg;
                    switch (data.error) {
                        case 20:
                            msg = "Username or password incorrect";
                            break;
                        default:
                            msg = "Authentication error";
                    }
                    return Promise.reject(msg);
                } else {
                    localStorage.setItem("token", data.token);
                    localStorage.setItem("role", data.role);
                    localStorage.setItem("user_id", data.user_id);
                    Cookies.set(
                        cookieName,
                        window.location.origin,
                        cookieParams
                    );
                }
            }
        );
    }
    // called when the user clicks on the logout button
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem("token");
        localStorage.removeItem("user_id");
        Cookies.remove(cookieName, cookieParams);
        return Promise.resolve();
    }
    // called when the API returns an error
    if (type === AUTH_ERROR) {
        const { status } = params;

        if (status === 401) {
            return Promise.reject();
        }
        return Promise.resolve();
    }
    // called when the user navigates to a new location
    if (type === AUTH_CHECK) {
        return localStorage.getItem("token")
            ? Promise.resolve()
            : Promise.reject();
    }
    if(type===AUTH_GET_PERMISSIONS){
        return Promise.resolve(getPermissions())
    }
    return Promise.reject("Unknown method");
};
