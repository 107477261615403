import React from "react";
import { connect } from "react-redux";
import { useForm } from "react-final-form";
import { Button, SaveButton, showNotification } from "react-admin";
//import { change, reset } from "redux-form";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { useInput } from "ra-core";

import IconCancel from "@material-ui/icons/Cancel";
import IconNewTab from "@material-ui/icons/OpenInNew";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import RemoveCircle from "@material-ui/icons/RemoveCircle";

import IconButton from "@material-ui/core/IconButton";

import { mime2ext } from "./helpers/format";

import { fileUriToURl } from "cms-toolbox/helpers/utils";

const title = "Edit image";

const maxPreviewWidth = 552;
const maxPreviewHeight = 380;

const polygon = ({ height, width, x, y, unit }) => {
    return `${x}${unit} ${y}${unit}, ${x + width}${unit} ${y}${unit}, ${x +
        width}${unit} ${y + height}${unit}, ${x}${unit} ${y + height}${unit}`;
};

const ImageEditor = connect()(
    class extends React.Component {
        state = {
            crop: {},
            previewStyle: null
        };

        onClose() {
            this.props.setShowDialog(false);
            //const { dispatch} = this.props;
            this.setState({ previewStyle: null });
            // dispatch(reset(formName));
        }

        handleCloseClick = () => {
            const { value } = this.props;

            if (!value.crop && !value.url) {
                // this.props.remove();
            } else {
                setTimeout(() => {
                    // Needed to trigger rendering crop area on form component.
                    // dispatch(change(REDUX_FORM_NAME, source, value));
                }, 10);
            }

            this.onClose();
        };

       /* componentWillReceiveProps(newProps) {
            if (
                newProps.value &&
                newProps.value.new &&
                newProps.value.new.rawFile
            ) {
                if (
                    !this.props.value ||
                    !this.props.value.new ||
                    this.props.value.new.rawFile.preview !==
                        newProps.value.new.rawFile.preview
                ) {
                    this.props.setShowDialog("triggered");
                    this.setState({
                        previewStyle: null,
                        crop: {}
                    });
                }
            }
        }*/

        render() {
            if (!this.props.value) {
                return this.dropText();
            }
            return (
                <div
                    className="crop-container"
                    onClick={e => {
                        e.stopPropagation();
                    }}
                    onDrop={e => {
                        e.stopPropagation();
                    }}
                >
                    {this.content()}
                </div>
            );
        }

        valid() {
            if (!this.state.sourceImage) {
                return false;
            }
            return true;
            /*
        if (!this.croppable()) {
            return true;
        }

        
        const { value, transforms } = this.props;
        const { crop } = this.state;
        const { width, height } = this.state.sourceImage;
        const orig = transforms._original;
        return (
            !value.new/* ||
            (crop.width &&
                crop.width >= 99 * (orig.width / width) &&
                crop.height &&
                crop.height >= 99 * (orig.height / height) &&
                crop.x >= 0 &&
                crop.y >= 0)*/
            //);
        }

        onImageLoaded = ({ width, height, ...rest }) => {
            const { value, transforms, showDialog } = this.props;
            const orig = transforms && transforms._original;

            const scaleDown = Math.min(
                1,
                Math.min(maxPreviewWidth, window.innerWidth - 144) / width,
                maxPreviewHeight / height
            );

            let crop;
            if (showDialog === "manual" && value.crop) {
                crop = value.crop;
            } else {
                if (orig) {
                    if (width < orig.width || height < orig.height) {
                        this.props.remove();

                        this.props.dispatch(
                            showNotification(
                                `Image does not meet the minimal dimensions (${
                                    orig.width
                                }px × ${orig.height}px)`,
                                "error"
                            )
                        );

                        return this.onClose();
                    }

                    const aspect = orig.width / orig.height;

                    const imgAspect = width / height;
                    const fitToHeight = imgAspect > aspect;

                    crop = {
                        unit: "%",
                        aspect,

                        x: 0,
                        y: 0
                    };
                    if (fitToHeight) {
                        crop.height = 100;
                        crop.width = 100 * (aspect / imgAspect);
                        crop.x = (100 - crop.width) * 0.5;
                    } else {
                        crop.width = 100;
                        crop.height = 100 * (imgAspect / aspect);
                        crop.y = (100 - crop.height) * 0.5;
                    }
                    /* dispatch(
                    change(REDUX_FORM_NAME, source, {
                        ...value,
                        crop
                    })
                );*/
                }else{
                    crop={
                          unit: "%",
                          width:100,
                          height:100,
                        aspect:width/height,

                        x: 0,
                        y: 0
                    }
                }
            }

            this.setState({
                crop,
                sourceImage: {
                    width,
                    height
                },
                //minWidth: (orig.width * scaleDown),
                // minHeight: (orig.height * scaleDown),
                previewStyle: {
                    width: width * scaleDown + "px",
                    height: height * scaleDown + "px"
                }
            });
            return false;
        };

        croppable() {
            const { value } = this.props;
            return (
                value.new && !~["image/svg+xml"].indexOf(value.new.rawFile.type)
            );
        }

        dropText() {
            const { transforms, col } = this.props;
            const orig = transforms && transforms._original;
            let desc = [];
            if (orig) {
                desc.push(
                    `Minimal dimensions: ${orig.width}px × ${orig.height}px.`
                );
            }
            if (col.accept) {
                desc.push(
                    `Accepted formats: ${Object.keys(col.accept)
                        .map(mime2ext)
                        .join(", ")}`
                );
            }
            return (
                <React.Fragment>
                    <p className="dropzone-empty">
                        {"Drop a picture to upload, or click to select it."}

                        {desc.length ? (
                            <small style={{ display: "block" }}>{`(${desc.join(
                                " "
                            )})`}</small>
                        ) : null}
                    </p>
                </React.Fragment>
            );
        }

        getValue(value) {
            if (value.new) {
                return value.new.undefined;
            }

            if (value.uri) {
                const fullUrl = fileUriToURl(value.uri);
                if (fullUrl) {
                    return fullUrl;
                }
            }
            return value.url;
        }

        content() {
            const { value, showDialog } = this.props;

            const src = this.getValue(value);
      
            const vcrop = value.crop;

            const croppable = this.croppable();
            if (
                /* (!value.new || value.crop) &&*/
                !showDialog
            ) {
                return (
                    <div
                        style={{
                            display: "grid",
                            gridAutoFlow: "column",
                            alignItems: "flex-start"
                        }}
                    >
                        <div
                            style={{
                                position: "relative"
                            }}
                           /* onClick={e => {
                                this.props.setShowDialog("manual");
                            }}
                            title="Edit image"*/
                        >
                            {!croppable || !vcrop ? (
                                <img
                                    src={croppable?src:src+'?w=200&h=200'}
                                    alt="preview"
                                    className="transp-bg img-preview"
                                />
                            ) : (
                                <React.Fragment>
                                    <img
                                        alt="preview"
                                        src={src}
                                        className="transp-bg"
                                        style={{
                                            display: "block",
                                            filter: "brightness(0.45)"
                                        }}
                                    />
                                    <img
                                        alt="preview"
                                        src={src}
                                        className="transp-bg"
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            display: "block",
                                            clipPath: `polygon(${polygon(
                                                vcrop
                                            )})`
                                        }}
                                    />
                                    <div
                                        style={{
                                            position: "absolute",
                                            border: "1px dashed white",
                                            boxSizing: "border-box",
                                            height: vcrop.height + vcrop.unit,
                                            width: vcrop.width + vcrop.unit,
                                            top: vcrop.y + vcrop.unit,
                                            left: vcrop.x + vcrop.unit
                                        }}
                                    />
                                </React.Fragment>
                            )}
                        </div>

                        <IconButton
                            onClick={() => this.props.remove()}
                            title={"Delete"}
                        >
                            <RemoveCircle />
                        </IconButton>
                    </div>
                );
            }

            const { crop } = this.state;

            return (
                <Dialog
                    //fullScreen={true}
                    //fullWidth
                    open={true}
                    onClose={this.handleCloseClick}
                    aria-label={title}
                >
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                        {/*<SimpleForm
                        form={formName}
                        onChange={({ alt }) => {
                            this.setState({ specs: { alt } });
                        }}
                        toolbar={null}
                    >
                        {fieldSet([
                            {
                                source: "alt",
                                type: "text",
                                placeholder: "Image alt-attribute",
                                props: {
                                    defaultValue: value.alt
                                }
                            }
                        ])}
                    </SimpleForm>
*/}
                        <Button
                            style={{ float: "right" }}
                            title={"Open image in new tab"}
                            href={src}
                            target="_blank"
                        >
                            <IconNewTab />
                        </Button>
                        {!croppable ? (
                            <img
                                alt="preview"
                                className="transp-bg"
                                src={src+'?w=1000&h=1000'}
                                style={{
                                    maxWidth: "100%"
                                }}
                            />
                        ) : (
                            <ReactCrop
                                src={src}
                                crop={crop}
                                imageStyle={
                                    this.state.previewStyle || {
                                        maxWidth: "none"
                                    }
                                }
                                style={{
                                    maxWidth: "none",
                                    ...this.state.previewStyle
                                }}
                                //minWidth={this.state.minWidth}
                                // minHeight={this.state.minHeight}
                                onChange={(crop, percentageCrop) => {
                                    this.setState({
                                        crop: percentageCrop
                                    });
                                }}
                                onImageLoaded={this.onImageLoaded}
                            />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <SaveButton
                            disabled={!this.valid()}
                            onClick={() =>
                                this.props.handleSubmit({
                                    crop,
                                    specs: this.state.specs
                                })
                            }
                            // need to provide something here or it crashes
                            // we handle everything in `handleSave`
                            handleSubmitWithRedirect={() => {}}
                            label="Save crop"
                        />
                        <Button
                            label="ra.action.cancel"
                            onClick={this.handleCloseClick}
                        >
                            <IconCancel />
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }
    }
);

export default props => {
    const [showDialog, setShowDialog] = React.useState(false);

    const { change } = useForm();

    const {
        input: { value }
    } = useInput(props);

    //const handleOpenDialog = () => setShowDialog(true);

    // const handleCloseDialog = () => setShowDialog(false);

    /*    const handleSave = () => {
        submitQC();
        setShowDialog(false);
    };
*/
    const handleSubmit = ({ crop, specs }) => {
        try {
            /* const { data } = await dataProvider("CREATE", props.resource, {
                data: values
            });
            setData(data);*/

            const { source } = props;

            const newVals = {
                ...value,
                ...specs,
                crop
            };
            change(
                source,

                newVals
            );
            setShowDialog(false);
        } catch (e) {
            console.log(`error in handleSubmit: ${e.message}`);
            // fetchEnd();
            // showNotification(e.message, "error");
        }
    };

    const remove = () => {
        change(
            props.source,

            null
        );
    };

    return (
        <ImageEditor
            {...props}
            handleSubmit={handleSubmit}
            showDialog={showDialog}
            setShowDialog={setShowDialog}
            remove={remove}
            value={value}
        />
    );
};
