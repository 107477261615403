import axios from 'axios';
import { HttpError } from 'react-admin';
import schemas from "taxi-schema"

// Handle HTTP errors.
export default () => {
  // Request interceptor
  axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');

      const newConfig = config;

      if (token) {
        newConfig.headers.Authorization = `Bearer ${token}`;
      }

      return newConfig;
    },
    err => Promise.reject(err),
  );

  // Response interceptor
  axios.interceptors.response.use(
    response => response,
    (error) => {
      const { status, data } = error.response||{};

      const findLabel=(machine_name)=>{
          if(data.model && schemas[data.model]){
            const foundSchemaField=schemas[data.model].find(({source})=>source===machine_name);
            if(foundSchemaField){
              return foundSchemaField.label
            }
          }
          return machine_name
      }

      if (status < 200 || status >= 300) {

        let message=data.message||"Unknown error"
        switch(data.code){
          case "ER_DATA_TOO_LONG":
           const match= message.match(/column (.*)$/i)
           if(match){
              const fieldName=match[1].trim().split(' ')[0].replace(/['"]+/g, '')
              message=`The value for field '${findLabel(fieldName)}' is too long`
           }
            break;
            case "ER_DUP_ENTRY":{
               const match= message.match(/^(.*)for key/i)
               if(match){
                message=match[1]
               }
             }
            break;
            default:
            
        }
        return Promise.reject(
          new HttpError(message, status),
        );
      }

      return Promise.resolve(error);
    },
  );
};
