import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Collapse, Button, IconButton } from "@material-ui/core";

import IconUndo from "@material-ui/icons/Undo";

import { withStyles } from "@material-ui/core/styles";
import { Avatar, DiffTable } from "cms-toolbox";

import { Link } from "react-admin";

import { moment } from "cms-config";

import schemas from "taxi-schema"

const styles = {
  truncate: {
    // width: 500,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  gutter:{
    padding:"8px 16px"
  }
};

const EventItemView = ({ event, classes, loadDataIntoForm }) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const { name, gender } = event.user || {};

  let resource;
  let id;

  let schema=[]
  if (event.article_id) {
    resource = "article";
    id = event.article_id;
    schema=schemas.article;

  } else if (event.page_id) {
    resource = "page";
    id = event.page_id;

    schema=schemas.page;
  } else if (event.product_id) {
    resource = "product";
    id = event.product_id;

    schema=schemas.product;
  }else if(event.view){
    resource='settings'
    id=event.view
  }

  const changes = event.diff.length || 0;

  let type=event.type;
  if(type==='suggestion'){
    type='submitted a revision request for'
  }

  return (
    <React.Fragment>
      <ListItem>
        {loadDataIntoForm ? (
          <IconButton onClick={loadDataIntoForm} title={"Revert the edit form values to the point before this revision"}>
            <IconUndo />
          </IconButton>
        ) : null}
        <ListItemAvatar>
          <Avatar seed={name} gender={gender} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <div className={classes.truncate}>
              <strong>{name || "Anonymous"}</strong>
              {loadDataIntoForm ? null : (
                <React.Fragment>
                  {` ${type} `}
                  <Link to={`/${resource}/${id}`}>{`${resource}/${id}`}</Link>
                </React.Fragment>
              )}
            </div>
          }
          secondary={
            moment(event.created).format("H:mm") +
            ` - ${changes} fields changed`
          }
        />
        <IconButton onClick={handleClick}>
         {open ? <ExpandLess /> : <ExpandMore />}
         </IconButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
      <div className={classes.gutter}>
        <DiffTable diff={event.diff} schema={schema} />
      </div>
      </Collapse>
    </React.Fragment>
  );
};

const EventItem = withStyles(styles)(EventItemView);

export default EventItem;
