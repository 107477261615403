const dev = process.env.NODE_ENV && process.env.NODE_ENV === "development";

export const hostname = window.location.host.split(":")[0];



export const region =dev?'dev':'taxi';

const apiBase = dev ? "//localhost:3001" : "https://taxi-haarlem-service.ew.r.appspot.com";

export const apiUrl = `${apiBase}/${region}`;

//export const siteDomain=dev?'taxihaarlemservice.nl':hostname.replace(/^cms\./,'')
export const siteDomain='taxi-website.vercel.app'

export const siteUrl = dev?'http://localhost:7000':`https://${siteDomain}`;
